<template>
    <BaseScene name="tongue-twister" :background="ResourceManager.Get('scenes.tonguetwister')">
        <div class="layer-2"></div>
        <div class="sprite" :class="[isOff ? 'off' : '', gender]"></div>

        <div class="readable-text">
            <span v-html="twisterAtCurrentIndex.text"></span>
        </div>

        <div class="time" v-if="!isOff">
            <span v-if="twisterData.time > 0">{{ twisterData.time }}</span>
            <!-- <span v-else>{{ this.cms.get("translations").get('time-over') }}</span> -->
        </div>     

        <BaseOverlay class="pick-twister-overlay" ref="pickTwisterOverlay" noCloseButton="true" :showOnInit="true" :dynamicHeight="true">
            <h3>{{ cms.get("translations").get('tongue-twister') }}</h3>

            <div class="sub-headline count">
                {{ twisterSelectionIndex + 1 }} / {{ twisters.length }}
            </div>
            <div class="twister-picker">
                <div class="twister-prev" @click="moveTwisterIndex(-1)">&laquo;</div>
                <div class="twister-preview">
                    <div class="title" v-html="twisterAtCurrentIndex.title"></div>
                </div>
                <div class="twister-next" @click="moveTwisterIndex(+1)">&raquo;</div>
            </div>

            <div class="sub-headline">{{ cms.get("translations").get('difficulty') }}</div>

            <div class="difficulty-selection">
                <div class="difficulty" @click="setDifficulty(1)" :class="{active: difficulty == 1}">
                    {{ cms.get("translations").get('difficulty-beginner') }}
                </div>
                <div class="difficulty" @click="setDifficulty(2)" :class="{active: difficulty == 2}">
                    {{ cms.get("translations").get('difficulty-intermediate') }}
                </div>
                <div class="difficulty" @click="setDifficulty(3)" :class="{active: difficulty == 3}">
                    {{ cms.get("translations").get('difficulty-professional') }}
                </div>
            </div>    

            <div class="listen-first">
                <audio controls ref="listenFirstPlayer">
                    <source :src="cms.media(twisterAtCurrentIndex['audio' + difficulty])" type="audio/mp3">
                    Ihr Browser unterstützt keine Audio-Dateien.
                </audio>
            </div>    

            <div class="gender-picker">
                <div :class="{active: gender == 'female'}" @click="gender = 'female'">♀</div>
                <div :class="{active: gender == 'male'}" @click="gender = 'male'">♂</div>
            </div>                

            <div class="buttons">
                <button class="help-button" @click="$refs.explanationOverlay.show()">
                    {{ cms.get("translations").get('help') }}
                </button>
                <button @click="startTwister(twisterAtCurrentIndex)">
                    {{ cms.get("translations").get('and-go') }}
                </button>
            </div>        
        </BaseOverlay>

        <BaseOverlay class="post-game-overlay" ref="postGameOverlay" noCloseButton="true" :showOnInit="false" :dynamicHeight="true">
            <h3>zeit abgelaufen!</h3>

            <div class="time-over" v-if="twisterData">
                <audio ref="recordedVoicePlayer" controls v-if="twisterData.audio.src">
                    <source :src="twisterData.audio.src" type="audio/mp3">
                    Ihr Browser unterstützt keine Audio-Dateien.
                </audio>

                <div class="buttons">                    
                    <button @click="reset">{{ cms.get("translations").get('again') }}</button>
                </div>
            </div>            
        </BaseOverlay>

        <BaseOverlay class="explanation-overlay" ref="explanationOverlay" :showOnInit="false" :width="900">
            <h3>{{ cms.get("translations").get('explanation-headline') }}</h3>
            <p class="description" style="text-align: center" v-html="cms.get('translations').get('tonguetwister-explanation')"></p>
        </BaseOverlay>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import BaseOverlay from '@/components/BaseOverlay';
import ResourceManager from "@/classes/resource-manager";
import EventBus from "@/classes/event-bus";
// import SceneTrigger from '../triggers/SceneTrigger.vue';

export default {
    inject: {
        cms: {
          default: null,
        },
    },    
    components: {
        BaseScene,
        BaseOverlay
        // SceneTrigger
    },
    mounted() {
        EventBus.Subscribe(EventBus.CHANNELS.Global, "locale-changed", data => {
            this.twisterSelectionIndex = 0;
            this.$refs.listenFirstPlayer?.load();
        });
    },
    data() {
        return {
            ResourceManager,
            loadedTwister: null,
            twisterData: null,
            difficulty: 1,
            started: false,
            twisterSelectionIndex: 0,
            isOff: true,
            gender: 'female'
        };
    },
    methods: {
        startTwister(twister) {            

            this.twisterData = {
                time: 0,
                audio: {
                    src: null
                }
            };

            switch (this.difficulty) {
                case 1:
                    this.twisterData.time = twister.time1;
                    break;
                case 2:
                    this.twisterData.time = twister.time2;
                    break;
                case 3:
                    this.twisterData.time = twister.time3;
                    break;
            }

            this.loadedTwister = twister;    
            this.$refs.pickTwisterOverlay.hide();
            this.startRecording();
        },
        reset() {
            this.isOff = true;
            this.loadedTwister = null;
            this.twisterData = null;
            this.started = false;

            this.$refs.postGameOverlay.hide();
            this.$refs.pickTwisterOverlay.show();
        },
        startRecording() {
            this.isOff = false;

            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.start();

                const audioChunks = [];

                mediaRecorder.addEventListener("dataavailable", event => {
                    audioChunks.push(event.data);
                });

                mediaRecorder.addEventListener("stop", () => {
                    const audioBlob = new Blob(audioChunks);
                    this.twisterData.audio.src = URL.createObjectURL(audioBlob);
                    this.$nextTick(() => {
                        this.$refs.recordedVoicePlayer.load();
                    });
                });

                const doTick = () => {
                    setTimeout(() => {                    
                        this.twisterData.time--;
                        if (this.twisterData.time <= 0) {
                            mediaRecorder.stop();
                            this.$refs.postGameOverlay.show();
                            this.isOff = true;
                        } else {
                            doTick();
                        }
                    }, 1000);                    
                }

                this.started = true;

                doTick();
            }).catch(e => {
                window.alert('DE: Kein Mikrofon gefunden\n\nPL: Nie znaleziono mikrofonu');
                this.reset();
            }); 
        },
        playAgain() {
            this.startTwister(this.loadedTwister);
            // this.startRecording();
        },
        tryAnother() {
            this.loadedTwister = null;
            this.twisterData = null;
            this.started = false;
        },
        setDifficulty(difficulty) {
            this.difficulty = difficulty;
            this.$refs.listenFirstPlayer.load();
        },
        moveTwisterIndex(direction) {
            if (direction == -1) {
                if (this.twisterSelectionIndex - 1 < 0) {
                    this.twisterSelectionIndex = this.twisters.length - 1;
                } else {
                    this.twisterSelectionIndex--;
                }
            } else {
                if (this.twisterSelectionIndex + 1 > this.twisters.length - 1) {
                    this.twisterSelectionIndex = 0;
                } else {
                    this.twisterSelectionIndex++;
                }                
            }

            this.$refs.listenFirstPlayer.load();
        }
    },
    computed: {
        twisters() {
            return this.cms.get('tonguetwister').get('twisters');
        },
        twisterAtCurrentIndex() {
            return this.twisters[this.twisterSelectionIndex];
        }
    }
}
</script>

<style lang="scss">
.scene-tongue-twister {

    .layer-2 {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: 1.5s;
        z-index: 80;
        pointer-events: none;        
        background-image: url('../../assets/images/tongue-twister-layer2.png');
    }

    .readable-text {
        @include width-ar(30);
        @include height-ar(12.5);
        @include xy-ar(-12, 3);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 81;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        span {
            text-align: center;
        }
    }

    .time {
        position: absolute;
        left: 50%;
        height: 50%;
        color: white;
        z-index: 81;
        width: 300px;
        margin-left: -150px;
        top: 25%;
        font-size: 50px;
        text-align: center;
        font-weight: bold;        
    }

    .time-over {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
    }

    .sprite {
        background-size: auto 100%;
        // width: 615px;
        @include width-ar(32.032);
        @include height-ar(34.896);
        // height: 670px;
        will-change: background-position;
        user-select: none;
        
        
        animation-iteration-count: infinite;   
        animation-duration: 2s;
        animation-delay: 0s;		                
        @include xy-ar(15.5, -2);
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;        

        &.off {
            animation-name: none !important;
        }

        &.male {
            animation-timing-function: steps(13);
            animation-name: sprite-cycle-male;
            background-image: url('../../assets/images/tongue-twister-sprite-male.png');       
            animation-duration: 0.75s;     
        }

        &.female {
            animation-timing-function: steps(17);
            animation-name: sprite-cycle;
            background-image: url('../../assets/images/tongue-twister-sprite.png');
        }
    }

    @keyframes sprite-cycle {
        100% {
            @include sprite-target-ar(-544.53125);
        }        
    }    

    @keyframes sprite-cycle-male {
        100% {
            @include sprite-target-ar(-416.416);
        }        
    }  

    h3 {
        color: white;
        @extend .font-hl;
        font-size: 60px;
        text-align: center;
        line-height: 60px;
        margin-bottom: 20px;
        margin-top: 0;
    }   

    button {
        background: $hl-1;
        outline: 0;
        border: 0;
        padding: 0px 20px;
        color: white;
        @extend .font-hl;
        font-size: 40px;
        cursor: pointer;
        margin: 0 10px;
        display: block;
        margin-top: 30px;        

        &.help-button {
            background: $hl-2;
        }
    }    

    .buttons {
        display: flex;
        justify-content: center;
    }

    .pick-twister-overlay {
        .sub-headline {
            text-align: center;
            font-weight: bold;
            @extend .font-hl;
            font-size: 40px;
            margin-top: 40px;

            &.count {
                font-size: 30px;
                margin-top: 0;
            }
        }

        .inner {
            // min-height: 30vh;
            // max-height: 600px;
        }

        .twister-picker {
            display: flex;
            align-items: center;
            justify-content: center;            
            user-select: none;
    
            .twister-preview {
                width: 20vw;
                margin: 0 20px;
                text-align: center;
                min-height: 10vh;
                display: flex;
                align-items: center;
                justify-content: center;
                
                .title {
                    color: $hl-1;
                    font-weight: bold;
                    font-size: 20px;
                }
            }
    
            .twister-prev,
            .twister-next {
                @extend .font-hl;
                font-size: 100px;
                cursor: pointer;
                transition: .35s;

                &:hover {
                    color: $hl-1;
                }
            }
        }

        .difficulty-selection {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .difficulty {
                margin: 0 20px;
                position: relative;
                cursor: pointer;

                &:after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    width: 0%;
                    height: 2px;
                    background: $hl-1;
                    left: 0;
                    transition: .35s;
                }

                &.active,
                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        .listen-first {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }

        .gender-picker {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            > div {
                margin: 0 15px;
                font-size: 40px;
                cursor: pointer;
                padding: 20px;
                transition: .35s;

                &.active,
                &:hover {
                    background: $hl-2;
                }
            }
        }        
    }

    .twisters {
        .twister {
            background: lightgray;
            opacity: 0.7;
            font-weight: bold;
            margin-bottom: 1px;
            max-width: 200px;
            cursor: pointer;
            transition: .35s;

            &:hover {
                background: gray;
            }
        }
    }

    .loaded-twister {
        text-align: center;
        color: white;
        margin-top: 300px;

        .time {
            font-size: 60px;
            font-weight: bold;
        }

        .text {
            font-weight: bold;
            margin-top: 20px;
        }

        .time-over {
            audio {
                display: block;
                margin: 20px auto;
            }
        }

        .difficulty-selection {
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            .difficulty {
                cursor: pointer;
                margin: 0 20px;
                user-select: none;

                &.active {
                    color: red;
                }
            }
        }
    }

}
</style>